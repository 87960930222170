import React from 'react';
import { useState } from 'react';
import Pete from './components/PeteProfile';
import './App.css';

const App = () => {
  return (
    <div className="min-h-screen bg-black">
      <Pete />
    </div>
  );
};

export default App;