import React, { useState } from 'react';
import { Brain, Code2, Database, ChartBar, BookOpen, Cpu } from 'lucide-react';

// Service Card with flip functionality
const FlipCard = ({ front, back }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div className="perspective-1000 h-[450px]">
      <div 
        onClick={() => setIsFlipped(!isFlipped)}
        className={`relative preserve-3d cursor-pointer w-full h-full duration-500 transition-transform ${
          isFlipped ? 'rotate-y-180' : ''
        }`}
      >
        <div className="absolute w-full h-full backface-hidden">
          {front}
        </div>
        <div className="absolute w-full h-full backface-hidden rotate-y-180">
          {back}
        </div>
      </div>
    </div>
  );
};

const Services = () => {
  const services = [
    {
      title: "Custom AI Solutions",
      icon: Brain,
      description: "Bespoke AI applications tailored to your business needs",
      details: {
        offerings: [
          "Machine Learning Models",
          "Natural Language Processing",
          "Computer Vision Systems",
          "Predictive Analytics"
        ],
        benefits: [
          "Increased Efficiency",
          "Cost Reduction",
          "Improved Decision Making",
          "Competitive Advantage"
        ]
      }
    },
    {
      title: "Data Analytics",
      icon: ChartBar,
      description: "Transform your data into actionable insights",
      details: {
        offerings: [
          "Business Intelligence",
          "Data Visualization",
          "Statistical Analysis",
          "Performance Metrics"
        ],
        benefits: [
          "Data-Driven Decisions",
          "Real-time Insights",
          "Performance Optimization",
          "Strategic Planning"
        ]
      }
    },
    {
      title: "Cloud Integration",
      icon: Database,
      description: "Scalable cloud-based AI solutions",
      details: {
        offerings: [
          "AWS Implementation",
          "Azure Services",
          "Cloud Architecture",
          "Security Setup"
        ],
        benefits: [
          "Scalability",
          "Cost Efficiency",
          "Global Access",
          "Robust Security"
        ]
      }
    },
    {
      title: "AI Education",
      icon: BookOpen,
      description: "Expert-led training and Talks",
      details: {
        offerings: [
          "Technical sessions",
          "Implementation Guidance"
        ],
        benefits: [
          "Team Upskilling",
          "Knowledge Transfer",
          "Best Practices",
          "Continuous Support"
        ]
      }
    },
    {
      title: "AI Consultation",
      icon: Cpu,
      description: "Strategic guidance for AI implementation",
      details: {
        offerings: [
          "Technology Assessment",
          "Strategy Development",
          "Implementation Planning",
          "ROI Analysis"
        ],
        benefits: [
          "Expert Guidance",
          "Risk Mitigation",
          "Optimized Solutions",
          "Clear Roadmap"
        ]
      }
    },
    {
      title: "Custom Development",
      icon: Code2,
      description: "End-to-end application development",
      details: {
        offerings: [
          "API Development",
          "System Integration",
          "UI/UX Design",
          "Production Deployment"
        ],
        benefits: [
          "Tailored Solutions",
          "Seamless Integration",
          "Scalable Architecture",
          "Ongoing Support"
        ]
      }
    }
  ];

  return (
    <div className="space-y-12 max-w-6xl mx-auto">
      {/* Header Section */}
      <div className="bg-gradient-to-r from-slate-900/40 via-blue-900/30 to-slate-900/40 rounded-2xl p-8 border border-blue-500/30
                   shadow-2xl hover:shadow-blue-500/10 transition-all duration-500">
        <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 via-blue-400 to-blue-300 mb-4">
          Our Services
        </h2>
        <p className="text-lg text-slate-300 leading-relaxed">
          We offer a comprehensive range of AI and data solutions to help your business 
          thrive in the digital age. Click on each service to learn more.
        </p>
      </div>

      {/* Services Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <FlipCard
            key={index}
            front={
              <div className="h-full bg-gradient-to-br from-gray-900/50 to-black/50 backdrop-blur-xl rounded-xl p-6
                           border border-slate-700/30 hover:border-blue-500/50 
                           transition-all duration-300 shadow-lg hover:shadow-blue-500/10">
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 rounded-xl bg-slate-800/50">
                    <service.icon className="w-8 h-8 text-cyan-400" />
                  </div>
                  <h3 className="text-xl font-semibold text-blue-400">{service.title}</h3>
                </div>
                <p className="text-slate-300 mb-6">{service.description}</p>
                <p className="absolute bottom-4 left-0 right-0 text-center text-cyan-400 text-sm">
                  Click to explore →
                </p>
              </div>
            }
            back={
              <div className="h-full bg-gradient-to-br from-gray-900/50 to-black/50 backdrop-blur-xl rounded-xl p-6
                           border border-slate-700/30 hover:border-blue-500/50 
                           transition-all duration-300 shadow-lg hover:shadow-blue-500/10">
                <div className="h-full flex flex-col">
                  <h3 className="text-xl font-semibold text-blue-400 mb-4">{service.title}</h3>
                  <div className="flex-1 space-y-4">
                    <div>
                      <h4 className="text-cyan-400 font-medium mb-2">What We Offer</h4>
                      <ul className="space-y-1">
                        {service.details.offerings.map((item, i) => (
                          <li key={i} className="text-slate-300 flex items-center gap-2">
                            <div className="w-1.5 h-1.5 bg-cyan-400 rounded-full"></div>
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="text-cyan-400 font-medium mb-2">Key Benefits</h4>
                      <ul className="space-y-1">
                        {service.details.benefits.map((item, i) => (
                          <li key={i} className="text-slate-300 flex items-center gap-2">
                            <div className="w-1.5 h-1.5 bg-cyan-400 rounded-full"></div>
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <p className="text-center text-cyan-400 text-sm mt-4">
                    Click to flip back
                  </p>
                </div>
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Services;