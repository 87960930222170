import React from 'react';
import { Mail, MessageSquare, Clock, Calendar, MapPin, Phone } from 'lucide-react';

const Contact = () => {
  return (
    <div className="space-y-12 max-w-6xl mx-auto">
      {/* Header Section */}
      <div className="bg-gradient-to-r from-slate-900/40 via-blue-900/30 to-slate-900/40 rounded-2xl p-8 border border-blue-500/30
                   shadow-2xl hover:shadow-blue-500/10 transition-all duration-500">
        <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 via-blue-400 to-blue-300 mb-4">
          Get In Touch
        </h2>
        <p className="text-lg text-slate-300 leading-relaxed">
          Ready to transform your business with AI? Contact us to discuss your needs and discover 
          how Summit AI can help you achieve your goals.
        </p>
      </div>

      {/* Contact Methods Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-gradient-to-br from-gray-900/50 to-black/50 backdrop-blur-xl rounded-xl p-8
                     border border-slate-700/30 hover:border-blue-500/50 
                     transition-all duration-500 transform hover:scale-105
                     hover:shadow-lg hover:shadow-blue-500/10">
          <div className="flex flex-col items-center text-center space-y-4">
            <div className="p-4 rounded-full bg-blue-500/10">
              <Mail className="w-8 h-8 text-cyan-400" />
            </div>
            <h3 className="text-xl font-semibold text-blue-400">Email Us</h3>
            <a 
              href="mailto:pete.summitai@gmail.com"
              className="text-slate-300 hover:text-cyan-400 transition-colors"
            >
              pete.summitai@gmail.com
            </a>
          </div>
        </div>

        <div className="bg-gradient-to-br from-gray-900/50 to-black/50 backdrop-blur-xl rounded-xl p-8
                     border border-slate-700/30 hover:border-blue-500/50 
                     transition-all duration-500 transform hover:scale-105
                     hover:shadow-lg hover:shadow-blue-500/10">
          <div className="flex flex-col items-center text-center space-y-4">
            <div className="p-4 rounded-full bg-blue-500/10">
              <MessageSquare className="w-8 h-8 text-cyan-400" />
            </div>
            <h3 className="text-xl font-semibold text-blue-400">Book a Consultation</h3>
            <p className="text-slate-300">
              Schedule a free initial consultation to discuss your project requirements
            </p>
          </div>
        </div>

        <div className="bg-gradient-to-br from-gray-900/50 to-black/50 backdrop-blur-xl rounded-xl p-8
                     border border-slate-700/30 hover:border-blue-500/50 
                     transition-all duration-500 transform hover:scale-105
                     hover:shadow-lg hover:shadow-blue-500/10">
          <div className="flex flex-col items-center text-center space-y-4">
            <div className="p-4 rounded-full bg-blue-500/10">
              <Calendar className="w-8 h-8 text-cyan-400" />
            </div>
            <h3 className="text-xl font-semibold text-blue-400">Training Enquiries</h3>
            <p className="text-slate-300">
              Contact us about our educational workshops and training sessions
            </p>
          </div>
        </div>
      </div>

      {/* Additional Information */}
      <div className="bg-gradient-to-br from-slate-900/40 to-black/40 rounded-2xl p-8 border border-blue-500/30">
        <h3 className="text-2xl font-semibold text-blue-400 mb-6 text-center">
          Why Work With Us
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {[
            {
              title: "Expert Consultation",
              description: "Get guidance from AI and data science experts"
            },
            {
              title: "Customized Solutions",
              description: "Solutions tailored to your specific needs"
            },
            {
              title: "Rapid Response",
              description: "Quick turnaround on inquiries and projects"
            },
            {
              title: "Ongoing Support",
              description: "Continuous support and maintenance available"
            }
          ].map((item, index) => (
            <div key={index} className="flex items-start gap-4">
              <div className="w-1.5 h-1.5 mt-2 bg-cyan-400 rounded-full"></div>
              <div>
                <h4 className="text-lg font-semibold text-blue-400 mb-2">{item.title}</h4>
                <p className="text-slate-300">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;