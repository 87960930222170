import React from 'react';
import { Brain, Code2, Database, Users, Award, BookOpen } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description, items }) => (
  <div className="bg-gradient-to-br from-gray-900/50 to-black/50 backdrop-blur-xl rounded-xl p-8
                 border border-slate-700/30 hover:border-blue-500/50 
                 transition-all duration-500 transform hover:scale-102
                 hover:shadow-lg hover:shadow-blue-500/10">
    <div className="flex items-start gap-4">
      <div className="p-3 rounded-xl bg-slate-800/50">
        <Icon className="w-8 h-8 text-cyan-400" />
      </div>
      <div>
        <h3 className="text-xl font-semibold text-blue-400 mb-3">{title}</h3>
        <p className="text-slate-300 mb-4">{description}</p>
        <div className="space-y-2">
          {items.map((item, i) => (
            <div key={i} className="flex items-center gap-2">
              <div className="w-1.5 h-1.5 bg-cyan-400 rounded-full"></div>
              <span className="text-slate-300">{item}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const About = () => {
  return (
    <div className="space-y-12 max-w-6xl mx-auto">
      {/* Main About Section */}
      <div className="bg-gradient-to-r from-slate-900/40 via-blue-900/30 to-slate-900/40 rounded-2xl p-8 border border-blue-500/30
                   shadow-2xl hover:shadow-blue-500/10 transition-all duration-500">
        <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 via-blue-400 to-blue-300 mb-6">
          Who We Are
        </h2>
        <p className="text-lg text-slate-300 leading-relaxed mb-6">
          Summit AI is a leading AI consultancy combining doctoral-level expertise with 
          practical industry experience. Our team brings together specialists in artificial intelligence, 
          data science, and computational research to deliver transformative solutions for businesses.
        </p>
        <p className="text-lg text-slate-300 leading-relaxed">
          We pride ourselves on bridging the gap between cutting-edge AI technology and 
          practical business applications, ensuring our clients receive solutions that 
          deliver measurable value and competitive advantage.
        </p>
      </div>

      {/* Expertise Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FeatureCard
          icon={Brain}
          title="Technical Expertise"
          description="Comprehensive expertise in advanced AI technologies and methodologies"
          items={[
            "Machine Learning & Deep Learning",
            "Natural Language Processing",
            "Computer Vision & Image Analysis",
            "Predictive Analytics"
          ]}
        />

        <FeatureCard
          icon={Database}
          title="Cloud & Infrastructure"
          description="Enterprise-level cloud solutions and architecture"
          items={[
            "AWS Certified Solutions",
            "Azure Cloud Services",
            "Scalable AI Infrastructure",
            "Secure Data Architecture"
          ]}
        />

        <FeatureCard
          icon={Code2}
          title="Development Capabilities"
          description="End-to-end development of AI solutions"
          items={[
            "Custom AI Applications",
            "API Development",
            "System Integration",
            "Production Deployment"
          ]}
        />

        <FeatureCard
          icon={BookOpen}
          title="Knowledge Sharing"
          description="High level talks and technical training"
          items={[
            "Talks",
            "Technical Training Sessions",
            "Strategy Consultation",
     
          ]}
        />
      </div>

      {/* Industry Experience Section */}
      <div className="bg-gradient-to-br from-slate-900/40 to-black/40 rounded-2xl p-8 border border-blue-500/30">
        <h2 className="text-2xl font-bold text-blue-400 mb-6 text-center">Industry Experience</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {[
            "Defense & Security",
            "Healthcare & Medical",
            "Financial Services",
            "Education & Training",
            "Business Intelligence"
          ].map((industry) => (
            <div key={industry} 
                 className="bg-slate-800/50 rounded-xl p-4 border border-slate-700/50
                          hover:border-cyan-500/30 transition-all duration-300
                          transform hover:scale-105">
              <p className="text-slate-300 text-center">{industry}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About