import React from 'react';
import { Code2, Lightbulb, GraduationCap, Brain } from 'lucide-react';

const Overview = () => {
  return (
    <div className="space-y-12 max-w-6xl mx-auto">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-slate-900/40 via-blue-900/30 to-slate-900/40 rounded-2xl p-12 border border-blue-500/30
                   shadow-2xl hover:shadow-blue-500/10 transition-all duration-500">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <div>
              <h1 className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 via-blue-400 to-blue-300 mb-4">
                Transforming Business Through AI
              </h1>
              <h2 className="text-3xl text-blue-400 mb-6">Custom AI & Data Solutions</h2>
            </div>
            <p className="text-lg text-slate-300 leading-relaxed">
              Summit AI delivers bespoke artificial intelligence solutions and data analytics expertise 
              to transform your business operations. We combine cutting-edge technology with 
              industry expertise to create practical, powerful solutions whilst sharing knowledge 
              through expert-led education.
            </p>
          </div>
          <div className="relative flex justify-center">
            <div className="absolute inset-0 bg-blue-500/20 rounded-full blur-3xl"></div>
            <img 
              src="/summit_logo.png"
              alt="Summit AI"
              className="w-80 h-80 object-contain"
            />
          </div>
        </div>
      </div>

      {/* Key Areas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[
          {
            title: "Custom Solutions",
            description: "Bespoke AI applications tailored to your specific business challenges",
            icon: Code2,
            gradient: "from-cyan-500/10 to-cyan-500/5"
          },
          {
            title: "Data Analytics",
            description: "Advanced analytics and insights powered by machine learning",
            icon: Brain,
            gradient: "from-blue-500/10 to-blue-500/5"
          },
          {
            title: "Consultation",
            description: "Expert guidance on AI strategy and implementation",
            icon: Lightbulb,
            gradient: "from-purple-500/10 to-purple-500/5"
          },
          {
            title: "Education",
            description: "Talks on AI and data science",
            icon: GraduationCap,
            gradient: "from-green-500/10 to-green-500/5"
          }
        ].map((item, index) => (
          <div
            key={item.title}
            className={`bg-gradient-to-br ${item.gradient} backdrop-blur-xl rounded-xl p-8 
                     border border-slate-700/30 hover:border-blue-500/50 
                     transition-all duration-500 transform hover:scale-105
                     hover:shadow-lg hover:shadow-blue-500/10`}
          >
            <div className="flex flex-col items-center text-center gap-4">
              <div className="p-3 rounded-xl bg-slate-800/50">
                <item.icon className="w-8 h-8 text-cyan-400" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-blue-400 mb-3">{item.title}</h3>
                <p className="text-slate-300 leading-relaxed">{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Why Choose Us */}
      <div className="bg-gradient-to-br from-slate-900/40 to-black/40 rounded-2xl p-8 border border-blue-500/30">
        <h2 className="text-2xl font-bold text-blue-400 mb-6 text-center">Why Choose Summit AI?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {[
            {
              title: "Expert Team",
              description: "PhD-level expertise in AI, machine learning, and data science"
            },
            {
              title: "Proven Track Record",
              description: "Successful implementations across multiple industries"
            },
            {
              title: "Custom Approach",
              description: "Solutions tailored to your specific business needs"
            },
            {
              title: "Knowledge Transfer",
              description: "Educational focus to empower your team"
            }
          ].map((item, index) => (
            <div key={item.title} className="flex items-start gap-4">
              <div className="w-1.5 h-1.5 mt-2 bg-cyan-400 rounded-full"></div>
              <div>
                <h3 className="text-lg font-semibold text-blue-400 mb-2">{item.title}</h3>
                <p className="text-slate-300">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Overview;
