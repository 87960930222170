import React, { useState, useEffect } from 'react';
import { 
  Building2,
  Users,
  Briefcase,
  Mail,
  Menu,
} from 'lucide-react';
import Overview from './Overview';
import About from './About';
import Services from './Services';
import Contact from './Contact';

const App = () => {
  const [activeSection, setActiveSection] = useState('overview');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hoveredSection, setHoveredSection] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
    setIsMobileMenuOpen(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const sections = [
    { id: 'overview', title: 'Overview', icon: Building2, component: Overview },
    { id: 'about', title: 'About Us', icon: Users, component: About },
    { id: 'services', title: 'Services', icon: Briefcase, component: Services },
    { id: 'contact', title: 'Contact', icon: Mail, component: Contact }
  ];

  const CurrentSection = sections.find(section => section.id === activeSection)?.component || null;

  const renderSidebarContent = () => (
    <div className="p-6 space-y-6">
{/* Logo Section with Enhanced Pulse Effect */}
<div className="flex justify-center mb-8">
  <div 
    className="relative"
    style={{
      animation: 'pulse-intensity-dramatic 2s infinite ease-in-out'
    }}
  >
    {/* Glowing background */}
    <div className="logo-glow" />
    {/* Blur effect */}
    <div className="absolute inset-0 bg-cyan-500/20 rounded-full blur-xl -z-10" />
    <img 
      src="/summit_logo.png"
      alt="Summit AI"
      className="w-32 h-24 object-contain relative z-10"
    />
  </div>
</div>

      {/* Navigation */}
      <nav className="space-y-2">
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => handleSectionChange(section.id)}
            onMouseEnter={() => setHoveredSection(section.id)}
            onMouseLeave={() => setHoveredSection(null)}
            className={`w-full text-left px-4 py-3 rounded-xl transition-all duration-300 
                     flex items-center justify-between group relative
                     ${activeSection === section.id 
                       ? 'bg-cyan-500/10 text-cyan-400 border border-cyan-500/30 shadow-lg shadow-cyan-500/20' 
                       : 'hover:bg-gray-800/50 text-gray-300 hover:border-cyan-500/20 border border-transparent'
                     }`}
          >
            <div className="flex items-center space-x-3">
              <section.icon 
                size={18} 
                className={`transition-transform duration-300 ${
                  hoveredSection === section.id ? 'scale-110' : ''
                }`}
              />
              <span className="font-medium">{section.title}</span>
            </div>
          </button>
        ))}
      </nav>

      {/* Footer */}
      <div className="mt-8 pt-8 border-t border-gray-800/50">
        <div className="text-sm text-gray-400/60">
          <p>Summit AI</p>
          <p>Version 1.0.0</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-black via-gray-900 to-black">
      {/* Desktop Sidebar */}
      <div className="hidden lg:block fixed top-0 left-0 h-full bg-black/90 border-r border-gray-800/50 w-64 z-40 backdrop-blur-xl">
        {renderSidebarContent()}
      </div>

      {/* Mobile Menu Button */}
      <div className="lg:hidden fixed top-4 right-4 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 bg-black/90 rounded-xl border border-cyan-500/20 hover:border-cyan-500/40 
                   transition-all duration-300 shadow-lg shadow-cyan-500/10"
        >
          <Menu size={24} className="text-cyan-400" />
        </button>
      </div>

      {/* Mobile Sidebar */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed top-0 left-0 h-full bg-black/90 border-r border-gray-800/50 w-64 z-40 backdrop-blur-xl shadow-lg">
          {renderSidebarContent()}
        </div>
      )}

      {/* Main Content Area */}
      <div className="flex-1 lg:pl-64">
        <div className="container mx-auto px-4 py-8">
          <div className="bg-black/80 rounded-xl shadow-2xl border border-cyan-500/20 p-8 overflow-hidden backdrop-blur-xl">
            {/* Decorative elements */}
            <div className="absolute -top-40 -right-40 w-80 h-80 bg-cyan-500/5 rounded-full blur-3xl pointer-events-none" />
            <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-cyan-500/5 rounded-full blur-3xl pointer-events-none" />

            <div className="relative border-b border-gray-800/50 pb-6 mb-6">
              <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 to-blue-500 mb-3">
                Summit AI
              </h1>
              <h2 className="text-xl text-cyan-400/80">AI & Data Solutions</h2>
            </div>

            <div className="relative">
              {CurrentSection && <CurrentSection />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;